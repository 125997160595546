.cart-body {
    background: #f1f3f6;
    width: 100%;
    min-height: calc(100vh - 68px);
    padding-bottom: 70px;

    .cart-step {
        padding-top: 40px;
    }
}

.breadcrumbs {
    background: #fff;
    padding: 10px 0;

    li {
        font-size: 12px;
        display: inline-block;
        text-transform: capitalize;

        &:after {
            content: "\f105";
            margin: 0 7px 0 4px;
            color: #747474;
            font-family: FontAwesome;
        }
    }
}

.clearfix {

    &:after,
    &:before {
        content: " ";
        display: block;
        height: 0;
        overflow: hidden;
    }

    &:after {
        clear: both;
    }
}

.cart-empty {
    &.error {
        padding: 50px 0 70px;
        border-bottom: 1px solid #e7e7e7;

        p {
            font-size: 1.125rem;
            color: #000;
            font-weight: 400;
            padding: 0 0 20px;
        }

        h3 {
            font-size: 24px;
            line-height: 32px;
            color: #333;
            font-weight: 400;
            font-family: robotoregular;
            text-transform: capitalize;
            padding: 0;
            margin: 0 0 25px;
        }
    }
}

.commen_btn {
    font-size: 16px;
    line-height: 20px;
    color: #fff !important;
    background: #000;
    padding: 10px 47px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
    font-family: robotolight;
}

.cart-empty-section {
    background-color: #fff;
    padding: 5px 0 10px;

    .cart-empty-content {
        text-align: center;

        .cart-empty-img {
            margin-bottom: 25px;
        }
    }

    .toppickssection {
        .toppicksheading {
            h2 {
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .cart-empty {
        margin-bottom: 0;
        &.error {
            padding: 0 0 20px 0;
            border-bottom: 1px solid #e7e7e7;
        }
    }

    .cart-empty-section {
        background-color: #f2f2f200;
        // padding: 5px 0 10px;
    
        .cart-empty-content {
            // text-align: center;
    
            .cart-empty-img {
                margin-bottom: 0;
            }
        }
    }

    .commen_btn {
        margin-top: 0;
    }
}