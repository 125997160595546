.toppickssection{
    padding: 40px 0px 0px;
    .toppicksheading{
        text-align: center;
        margin: 0 0 40px;
        h2{
            font-size: 22px;
            line-height: 24px;
            color: #333333;
            font-weight: 500;
            font-family: robotomedium;
            text-transform: capitalize;
            margin: 0px;
        }
        p{
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            letter-spacing: 0.02em;
            margin: 0px;
        }
    }
    .toppickscontent{
        padding-bottom: 15px;
    }
    .toppickshecard{
        display: inline-block;
        margin-bottom: 25px;
        text-align: center;
        max-width: 192px;
        .toppicksimg{
            margin-bottom: 5px;
            img{
                width: auto;
                height: auto;
            }

        }
        .toppickstext{
            .categoryname{
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                font-weight: 500;
                font-family: "pangrammedium";
                text-transform: capitalize;
                letter-spacing: 0.03em;
                margin: 0px;
                transition: 0.3s all ease-in-out;
            }
        }
        &:hover{
            .toppickstext{
                .categoryname{
                    color: #E27A34;
                }
            }

        }
    }


}
// storesection
.storesection {
    margin: 50px 0px;
    .storeimg {
        a{
            display: inline-block;
            width: 100%;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
